import AuthenticationService from '@/services/authentication/authentication';
import ClientIdAuthenticationService from '@/services/authentication/clientid-authentication';
import JWTAuthenticationService from '@/services/authentication/jwt-authentication';
import ChatParametersProvider from '@/services/chat-parameters';
import HttpClient from '@/utils/http-client';

export function authServiceFactory(
    params: ChatParametersProvider, http: HttpClient
): AuthenticationService {
    if (params.chatParams.authMode === 'jwt') {
        return new JWTAuthenticationService(params, http);
    }
    if (params.chatParams.authMode === 'clientId') {
        return new ClientIdAuthenticationService(params, http);
    }
    throw new Error(`no AuthService found matching mode ${params.chatParams.authMode}`);
}

import { CommonAuthenticationService } from '@/services/authentication/common-authentication';
import ChatParametersProvider from '@/services/chat-parameters';
import HttpClient from '@/utils/http-client';
import { Observable, of } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { map, tap } from 'rxjs/operators';
import { inject, injectable } from 'vue-typescript-inject';


@injectable()
export default class JWTAuthenticationService extends CommonAuthenticationService<string> {
    private storage = window.localStorage;

    constructor(
        @inject() chatParametersProvider: ChatParametersProvider,
        @inject() httpClient: HttpClient,
    ) {
        super(chatParametersProvider, httpClient);
    }


    public userNeverVisited(): boolean {
        return !this.storage.getItem(this.tokenKey);
    }

    private get tokenKey(): string {
        return `${this.storagePrefix}-jwt-token`;
    }

    protected handle401Error(error: any): void {
        this.clearToken();
    }

    protected handle403Error(error: any): void {
        return;
    }

    protected getTokenFromStorage(): string | null {
        return this.storage.getItem(this.tokenKey);
    }

    protected getToken(): Observable<string> {
        return this.tokenFromStorage();
    }

    protected authenticateOnGateway(): Observable<string> {
        const url = `${this.chatEndpoint}authentication/public?bot_name=${this.botName}&bot_environment=${this.botEnvironment}`;
        return this.httpClient
            .request({url}, undefined, (res) => res.xhr?.getResponseHeader('Authorization'))
            .pipe(
                map((bearerToken: string) => this.parseTokenFromAuthorizationHeader(bearerToken || '')),
                tap((strToken) => this.storeToken(strToken)),
            );
    }

    protected setHttpAuthInterceptor(encodedToken: string): void {
        this.httpClient.setAuthInterceptor((req: Partial<AjaxRequest>) => {
            req.headers = {Authorization: `Bearer ${encodedToken}`, ...req.headers};
            return req;
        });
    }

    private clearToken() {
        this.storage.removeItem(this.tokenKey);
    }

    private storeToken(token: string) {
        this.storage.setItem(this.tokenKey, token);
    }

    private parseTokenFromAuthorizationHeader(header: string): string {
        const regex = /Bearer\s(.*)/;
        const matches = header.match(regex);
        if (matches === null) {
            throw new Error('Cannot parse token from gateway');
        } else {
            return matches[1];
        }
    }


    private tokenFromStorage(): Observable<string> {
        const tokenFromStorage = this.cache ?? this.getTokenFromStorage();
        return tokenFromStorage
            ? of(tokenFromStorage)
            : this.authenticateOnGateway().pipe(tap(() => (this.updateHttpClient = true)));
    }
}

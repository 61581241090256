import OnMountIsLoadedMixin from '@/mixins/on-mount-is-loaded';
import { IBotMessageContent } from '@/models/bot-models';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class OnMountIsLoadedAndBotAnswerWithButtonsMixin extends OnMountIsLoadedMixin {
    @Prop({ default: () => ({}) }) public content!: IBotMessageContent;

    get hasButtons(): boolean {
        return 'buttons' in this.content && this.content.buttons !== null && this.content.buttons.length > 0;
    }
}

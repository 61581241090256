import { IConversationBlock, IReferral, IUserMessage } from '@/models/bot-models';
import SERVICE_IDENTIFIERS from '@/models/service-identifiers';
import AuthenticationService from '@/services/authentication/authentication';
import ChatParametersProvider, { IChatParameters } from '@/services/chat-parameters';
import MessageSocketService from '@/services/message-socket';
import store from '@/store';
import HttpClient from '@/utils/http-client';
import { from, Observable, throwError, timer } from 'rxjs';
import { catchError, exhaustMap, filter, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { inject, injectable } from 'vue-typescript-inject';

@injectable()
export default class ShortPollingService implements MessageSocketService {
    private sendingNewMessages = false;

    private http: HttpClient;
    private authService: AuthenticationService;
    private chatParametersProvider: ChatParametersProvider;
    private lastTimestamp!: number | null;

    constructor(
        @inject() chatParametersProvider: ChatParametersProvider,
        @inject(SERVICE_IDENTIFIERS.AUTH) authService: AuthenticationService,
        @inject() httpClient: HttpClient,
    ) {
        this.authService = authService;
        this.chatParametersProvider = chatParametersProvider;
        this.http = httpClient;
    }
    private get params(): IChatParameters {
        return this.chatParametersProvider.chatParams;
    }

    public setSendingNewMessages(value: boolean): void {
        this.sendingNewMessages = value;
    }

    public get(): Observable<IConversationBlock> {
        return this.startPolling();
    }

    public send(message: IUserMessage): void {
        const url =
            `${this.params.chatEndpoint}` +
            `?_${new Date().getTime()}` +
            `&bot_name=${this.params.botName}` +
            `&bot_environment=${this.params.botEnvironment}`;
        this.authService
            .authenticate()
            .pipe(
                switchMap(() =>
                    this.http.authenticatedRequest({
                        url,
                        method: 'POST',
                        body: {
                            ...message,
                            ...{
                                extra: {
                                    url: window.location.href,
                                },
                            },
                        },
                        headers: {
                            'Content-type': 'application/json',
                        },
                    }),
                ),
                catchError((e) => {
                    this.authService.handleAuthError(e);
                    return throwError(e);
                }),
                retry(3),
            )
            .subscribe();
    }

    private startPolling(): Observable<IConversationBlock> {
        return this.authService.authenticate().pipe(
            switchMap(() => timer(0, this.params.pollingIntervalMs)),
            exhaustMap(() => this.poll()), // if a request is pending, we wait for it to complete
            catchError((e) => {
                this.authService.handleAuthError(e);
                return throwError(e);
            }),
            retry(5),
        );
    }

    private sendPing(isFirstMessage: boolean): void {
        const isFromCampaign = this.chatParametersProvider.chatParams.isCampaign;
        const jwtReference = this.chatParametersProvider.chatParams.jwtReference;
        const referral: IReferral = this.chatParametersProvider.getReferral();
        const startWithGotoNode = isFirstMessage || (!!jwtReference);
        const referralShouldBeSent = referral.ref.length !== 0 && this.params.referralEntityName;
        if (!referralShouldBeSent && !startWithGotoNode && !isFromCampaign) {
            return;
        }
        let message: IUserMessage;
        if (jwtReference && isFromCampaign) {
            message = {
                content_type: 'campaign',
                content: {
                    default_scenario: this.params.entryPoint,
                    encoded_reference: this.params.jwtReference,
                },
            };
        } else {
            message = {
                content: {
                    text: '',
                    action: '',
                    entities: referralShouldBeSent ? {[this.params.referralEntityName]: referral} : {},
                    intent: '',
                    goto: startWithGotoNode ? this.params.entryPoint : '',
                },
                content_type: 'postback',
            };
            if (jwtReference) {
                message.pre_input_tasks = [{
                    type: 'set-reference',
                    encoded_reference: this.params.jwtReference,
                }];
            }
        }
        this.sendingNewMessages = true;
        this.send(message);
    }

    private poll(): Observable<IConversationBlock> {
        // tslint:disable-next-line:prefer-template
        let url =
            `${this.params.chatEndpoint}` +
            `?_${new Date().getTime()}` +
            `&bot_name=${this.params.botName}` +
            `&bot_environment=${this.params.botEnvironment}`;
        if (this.lastTimestamp) {
            this.sendingNewMessages = true;
            url += `&last_message_date=${this.lastTimestamp}`;
        }
        return this.http
            .authenticatedRequest({
                url,
                method: 'GET',
            })
            .pipe(
                tap((jsonResponse: IConversationBlock[]) => {
                    if (!this.sendingNewMessages && jsonResponse) {
                        this.sendPing(jsonResponse.length === 0);
                    }
                }),
                // emit for each conversation block
                mergeMap((blocks: IConversationBlock[]) => from(blocks)),
                tap(
                    (cv: IConversationBlock) =>
                        (this.lastTimestamp = Math.max(cv.hook_reception_date || 0, this.lastTimestamp || 0)),
                ),
                tap((cv: IConversationBlock) => {
                    store.commit('setConversationContext', cv.bot_message.context);
                }),
                filter(
                    (cv: IConversationBlock) =>
                        !(cv.user_message && cv.user_message.extra && cv.user_message.extra.is_api_message),
                ),
            );
    }
}

import { Component, Vue } from 'vue-property-decorator';
// tslint:disable ordered-imports
import 'reflect-metadata';
import ChatErrorHeader from '@/components/misc/ChatErrorHeader/ChatErrorHeader.vue';
import IframeVisualizer from '@/components/misc/IframeVisualizer/IframeVisualizer.vue';
import UserInput from '@/components/inputs/UserInput/UserInput.vue';
import MessagesContainer from '@/components/messages/MessagesContainer/MessagesContainer.vue';
import GreetingMessage from '@/components/misc/GreetingMessage/GreetingMessage.vue';
import SERVICE_IDENTIFIERS from '@/models/service-identifiers';
import ChatService from '@/services/chat';
import ShortPollingService from '@/services/short-polling';
import ChatParametersProvider from '@/services/chat-parameters';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { inject } from 'vue-typescript-inject';
import { mapState } from 'vuex';
import store from '@/store';
import HttpClient from '@/utils/http-client';
import { authServiceFactory } from '@/services/authentication/authentication-service-factory';
import { PushNotificationsStorageService } from '@/services/push-notifications-storage';

library.add(faTimes);

@Component({
    providers: [
        ChatParametersProvider,
        ChatService,
        HttpClient,
        PushNotificationsStorageService,
        {
            provide: SERVICE_IDENTIFIERS.SOCKET,
            useClass: ShortPollingService,
        },
        {
            provide: SERVICE_IDENTIFIERS.AUTH,
            useFactory: authServiceFactory,
            deps: [ChatParametersProvider, HttpClient],
        },
    ],
    components: { MessagesContainer, UserInput, ChatErrorHeader, IframeVisualizer, GreetingMessage },
    computed: { ...mapState(['startButtonHidden']) },
})
export default class App extends Vue {
    public chatIsClosed = true;

    public startButtonHidden!: boolean;

    @inject() private readonly chatService!: ChatService;

    @inject() private readonly chatParametersProvider!: ChatParametersProvider;

    public get greetingMessageContent(): string | undefined {
        return this.chatParametersProvider.chatParams.greetingMessageContent;
    }

    public get startButtonDisplayDelay(): number {
        return this.chatParametersProvider.chatParams.startButtonDisplayDelayMs;
    }

    public get greetingMessageDelay(): number {
        return this.chatParametersProvider.chatParams.greetingMessageDelayMs;
    }

    public get botDisplayedName(): string | undefined {
        return this.chatParametersProvider.chatParams.botDisplayedName;
    }

    public mounted(): void {
        this.setStartButtonStatus();
        this.displayGreetingMessage();
        if (this.chatParametersProvider.chatParams.opened === 'true') {
            this.toggleChat();
        }
        store.state.hasNewMessages$.subscribe((res) => {
            if (res && this.chatIsClosed) {
                this.toggleChat();
            }
        });
        this.$el.addEventListener('openChat', () => {
            if (this.chatIsClosed) {
                this.toggleChat();
            }
        });
    }

    public toggleChat(): void {
        this.chatIsClosed = !this.chatIsClosed;
        if (!this.chatIsClosed) {
            this.chatService.init();
        }
    }

    public setStartButtonStatus(): void {
        if (this.startButtonDisplayDelay !== 0) {
            store.dispatch('hideStartButton');
        }
        if (this.startButtonDisplayDelay > 0) {
            setTimeout((): void => {
                store.dispatch('showStartButton');
            }, this.startButtonDisplayDelay);
        }
    }

    public displayGreetingMessage(): void {
        if (this.chatService.userNeverUsedChat() && this.startButtonDisplayDelay >= 0) {
            setTimeout((): void => {
                if (this.chatIsClosed) {
                    store.dispatch('showGreetingMessage');
                }
            }, this.startButtonDisplayDelay + this.greetingMessageDelay);
        }
    }
}

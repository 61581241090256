import { IConversationBlock, IPostbackButton, IPostbackSuggestion, IUserMessage } from '@/models/bot-models';
import SERVICE_IDENTIFIERS from '@/models/service-identifiers';
import AuthenticationService from '@/services/authentication/authentication';
import MessageSocketService from '@/services/message-socket';
import { PushNotificationsStorageService } from '@/services/push-notifications-storage';
import store from '@/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { inject, injectable } from 'vue-typescript-inject';

@injectable()
export default class ChatService {
    public isInit = new BehaviorSubject<boolean>(false);

    constructor(
        @inject(SERVICE_IDENTIFIERS.SOCKET) private readonly messageSocketService: MessageSocketService,
        @inject(SERVICE_IDENTIFIERS.AUTH) private readonly authService: AuthenticationService,
        @inject() private readonly storePushNotificationsService: PushNotificationsStorageService,
    ) {}

    public sendUserMessage(message: string) {
        this.sendServerMessage({
            content_type: 'text',
            content: {
                value: message,
            },
        });
    }

    public userNeverUsedChat(): boolean {
        return this.authService.userNeverVisited();
    }

    public storePushNotificationSubscription(pushSubscription: PushSubscription): Observable<any> {
        return this.storePushNotificationsService.storePushNotificationSubscription(pushSubscription);
    }

    public sendPostbackSuggestion(postback: IPostbackSuggestion) {
        delete postback.suggestion_type;
        this.sendServerMessage({
            content_type: 'postback',
            content: {
                ...postback,
            },
        });
    }

    public sendLocationSuggestion(position: Position) {
        this.sendServerMessage({
            content_type: 'location',
            content: {
                coordinates: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                },
            },
        });
    }

    public sendPostbackButton(button: IPostbackButton) {
        this.sendServerMessage({
            content_type: 'postback',
            content: {
                ...button.payload,
                text: button.title,
            },
        });
    }

    public init() {
        if (this.isInit.getValue()) {
            return;
        }
        this.isInit.next(true);
        store.dispatch('showStartButton');
        store.dispatch('hideGreetingMessage');
        this.messageSocketService.get().subscribe((block: IConversationBlock) => {
            store.dispatch('renderConversationBlock', block);
            store.dispatch('popChatIfNotDisplayed', block);
        });
    }

    private sendServerMessage(message: IUserMessage) {
        store.dispatch('showPendingUserMessage', message);
        this.messageSocketService.send(message);
    }
}

import ChatParametersProvider from '@/services/chat-parameters';
import HttpClient from '@/utils/http-client';
import { EMPTY, Observable } from 'rxjs';
import { inject, injectable } from 'vue-typescript-inject';

@injectable()
export class PushNotificationsStorageService {
    private http: HttpClient;
    private readonly chatEndpoint: string;
    private readonly botName: string;
    private readonly botEnvironment: string;
    private readonly pushNotificationPublicKey: string;

    constructor(@inject() chatParametersProvider: ChatParametersProvider, @inject() httpClient: HttpClient) {
        this.http = httpClient;
        this.chatEndpoint = chatParametersProvider.chatParams.chatEndpoint;
        this.botName = chatParametersProvider.chatParams.botName;
        this.botEnvironment = chatParametersProvider.chatParams.botEnvironment;
        this.pushNotificationPublicKey = chatParametersProvider.chatParams.pushNotificationPublicKey;
    }

    public storePushNotificationSubscription(pushSubscription: PushSubscription): Observable<any> {
        if (this.pushNotificationPublicKey) {
            return this.http.authenticatedRequest({
                url: `${this.chatEndpoint}store_push_notification_subscription?bot_name=${this.botName}&bot_environment=${this.botEnvironment}`,
                method: 'POST',
                body: pushSubscription,
                headers: {
                    'Content-type': 'application/json',
                },
            });
        }
        return EMPTY;
    }
}

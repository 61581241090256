import ChatService from '@/services/chat';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from 'vue-typescript-inject';
import { mapState } from 'vuex';

library.add(faPaperPlane);

@Component({
    computed: mapState(['isTyping']),
})
export default class UserInput extends Vue {
    public textInput = '';

    @inject() private readonly chatService!: ChatService;

    private isTyping!: boolean;
    private unwatch: (() => void) | undefined;

    public sendTextMessage(): void {
        if (this.textInput && !this.isTyping) {
            this.chatService.sendUserMessage(this.textInput);
            this.textInput = '';

            this.$nextTick(this.resizeTextarea);
        }
    }

    public resizeTextarea() {
        const userInputArea = this.$refs.userInputArea as HTMLElement;
        if (userInputArea) {
            // Needed to fix scrollHeight not resetting after programmatically changing content
            userInputArea.style.height = `auto`;
            userInputArea.style.height = `${userInputArea.scrollHeight}px`;
        }
    }

    public created() {
        this.resizeTextarea();
        this.unwatch = this.$store.watch<boolean>(
            (state) => state.isTyping,
            (isTyping) => {
                const userInputArea = this.$refs.userInputArea as HTMLFieldSetElement;
                if (!isTyping && userInputArea !== undefined) {
                    userInputArea.disabled = false;
                    userInputArea.focus();
                } else if (isTyping && userInputArea !== undefined) {
                    userInputArea.disabled = true;
                }
            },
        );
    }

    public destroy() {
        if (this.unwatch) {
            this.unwatch();
        }
    }
}

import AuthenticationService from '@/services/authentication/authentication';
import ChatParametersProvider from '@/services/chat-parameters';
import HttpClient from '@/utils/http-client';
import { defer, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { inject, injectable } from 'vue-typescript-inject';

@injectable()
export abstract class CommonAuthenticationService<credentialsType> implements AuthenticationService {
    protected readonly botName: string;
    protected readonly botEnvironment: string;
    protected readonly chatEndpoint: string;
    protected readonly storagePrefix: string;
    protected readonly httpClient: HttpClient;

    protected updateHttpClient: boolean = true;
    protected cache?: credentialsType;

    constructor(@inject() chatParametersProvider: ChatParametersProvider, @inject() httpClient: HttpClient) {
        this.httpClient = httpClient;
        this.botName = chatParametersProvider.chatParams.botName;
        this.botEnvironment = chatParametersProvider.chatParams.botEnvironment;
        this.chatEndpoint = chatParametersProvider.chatParams.chatEndpoint;
        this.storagePrefix = chatParametersProvider.chatParams.storagePrefix + this.botName + this.botEnvironment;
    }

    public authenticate(): Observable<void> {
        return defer(() =>
            this.getToken().pipe(
                tap((token: credentialsType) => (this.cache = token)),
                map((token: credentialsType) => this.addAuthorizationHeaderInterceptor(token)),
            ),
        );
    }

    public handleAuthError(error: any): void {
        this.cache = undefined;
        if (error.status === 401) {
            this.handle401Error(error);
        }
        if (error.status === 403) {
            this.handle403Error(error);
        }
    }

    public abstract userNeverVisited(): boolean;

    protected abstract getToken(): Observable<credentialsType>;

    protected addAuthorizationHeaderInterceptor(token: credentialsType): void {
        if (this.updateHttpClient || this.httpClient.authIsUndefined()) {
            this.setHttpAuthInterceptor(token);
            this.updateHttpClient = false;
        }
    }

    protected abstract getTokenFromStorage(): credentialsType | null;

    protected abstract authenticateOnGateway(): Observable<credentialsType>;

    protected abstract setHttpAuthInterceptor(token: credentialsType): void;

    protected abstract handle401Error(error: any): void;

    protected abstract handle403Error(error: any): void;
}

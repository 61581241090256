import MessageMixin from '@/mixins/message-mixin';
import { IBotMessageContent } from '@/models/bot-models';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class BotAnswerWithButtonsMixin extends MessageMixin {
    @Prop({ default: () => ({}) }) public content!: IBotMessageContent;

    get hasButtons(): boolean {
        return 'buttons' in this.content && this.content.buttons !== null && this.content.buttons.length > 0;
    }
}

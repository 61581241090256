import { CommonAuthenticationService } from '@/services/authentication/common-authentication';
import { HttpClientError } from '@/utils/http-client';
import { Observable, of, throwError } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { catchError, tap } from 'rxjs/operators';
import { injectable } from 'vue-typescript-inject';

export interface Credentials {
    client_id: string;
    token: string;
}

@injectable()
export default class ClientIdAuthenticationService extends CommonAuthenticationService<Credentials> {
    private storage = window.localStorage;

    public userNeverVisited(): boolean {
        return !this.storage.getItem(this.clientIdKey);
    }

    private get tokenKey(): string {
        return `${this.storagePrefix}-botToken`;
    }
    private get clientIdKey(): string {
        return `${this.storagePrefix}-botConversationId`;
    }

    protected handle401Error(error: any): void {
        this.clearToken();
    }

    protected handle403Error(error: any): void {
        this.clearToken();
        this.clearClientId();
    }

    protected getTokenFromStorage(): Credentials | null {
        const clientId = this.storage.getItem(this.clientIdKey);
        const token = this.storage.getItem(this.tokenKey);
        if (clientId && token) {
            return {
                token,
                client_id: clientId,
            };
        }
        return null;
    }

    protected getToken(): Observable<Credentials> {
        const tokenFromStorage = this.cache ?? this.getTokenFromStorage();
        return tokenFromStorage
            ? of(tokenFromStorage)
            : this.authenticateOnGateway().pipe(tap(() => (this.updateHttpClient = true)));
    }

    protected authenticateOnGateway(): Observable<Credentials> {
        let url = `${this.chatEndpoint}authenticate?bot_name=${this.botName}&bot_environment=${this.botEnvironment}`;
        const clientId = this.storage.getItem(this.clientIdKey);
        if (clientId) {
            url += `&client_id=${clientId}`;
        }
        return this.httpClient.request({url}).pipe(
            tap((creds: Credentials) => this.storeCredentials(creds)),
            catchError((err: HttpClientError) => {
                if (err.status === 404 && err.message === 'Unknown client ID') {
                    this.clearClientId();
                    return this.authenticateOnGateway();
                }
                return throwError(err);
            }),
        );
    }

    protected setHttpAuthInterceptor(creds: Credentials): void {
        this.httpClient.setAuthInterceptor((req: Partial<AjaxRequest>) => {
            if (req.body) {
                req.body.extra = {...req.body.extra, client_id: creds.client_id};
            }
            const [path, ...params] = req.url!.split('?');
            const newPath = path.endsWith('store_push_notification_subscription')
                ? path.replace(
                    'store_push_notification_subscription',
                    `${creds.token}/store_push_notification_subscription`,
                )
                : `${path}${path.endsWith('/') ? '' : '/'}${creds.token}`;

            const newParams = `client_id=${creds.client_id}${params.length > 0 ? `&${params.join('?')}` : ''}`;
            req.url = [newPath, newParams].join('?');
            return req;
        });
    }

    private storeCredentials(creds: Credentials) {
        this.storage.setItem(this.tokenKey, creds.token);
        this.storage.setItem(this.clientIdKey, creds.client_id);
    }

    private clearToken() {
        this.storage.removeItem(this.tokenKey);
    }
    private clearClientId() {
        this.storage.removeItem(this.clientIdKey);
    }
}

import store from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
    computed: mapState(['greetingMessage']),
})
export default class GreetingMessage extends Vue {
    @Prop({ default: null }) public messageContent!: string;

    private greetingMessage!: boolean;

    public dismiss(): void {
        store.dispatch('hideGreetingMessage');
    }

    public startConversation(): void {
        const element = document.getElementById('randy-webchat');
        const event = document.createEvent('Event');
        event.initEvent('openChat', true, true);
        if (element) {
            element.dispatchEvent(event);
        }
    }
}

import { IReferral } from '@/models/bot-models';
import { injectable } from 'vue-typescript-inject';

export interface IChatParameters {
    botName: string;
    botEnvironment: string;
    chatEndpoint: string;
    entryPoint: string;
    pollingIntervalMs: number;
    storagePrefix: string;
    pushNotificationPublicKey: string;
    referralEntityName: string;
    greetingMessageContent: string;
    greetingMessageDelayMs: number;
    startButtonDisplayDelayMs: number;
    authMode: string;
    opened?: string;
    jwtReference: string;
    isCampaign: boolean;
    botDisplayedName: string;
}

@injectable()
export default class ChatParametersProvider {
    private readonly chatParameters: IChatParameters;
    private readonly referralKeys: string[] = ['ref', 'referral'];

    private divAttributes?: NamedNodeMap;
    private urlParams?: URLSearchParams;

    constructor() {
        this.getUrlAndDivValues();
        this.chatParameters = {
            botName: this.getConfig('botName'),
            botEnvironment: this.getConfig('botEnvironment'),
            chatEndpoint: this.getConfig('chatEndpoint'),
            entryPoint: this.getConfig('entryPoint', 'messenger_entrypoint'),
            pollingIntervalMs: +this.getConfig('pollingIntervalMs', '1000'),
            storagePrefix: this.getConfig('storagePrefix', 'RW'),
            pushNotificationPublicKey: this.getConfig('pushNotificationPublicKey', ''),
            referralEntityName: this.getConfig('referralEntityName', 'facebook_referral'),
            greetingMessageContent: this.getConfig('greetingMessageContent', ''),
            greetingMessageDelayMs: +this.getConfig('greetingMessageDelayMs', '1500'),
            startButtonDisplayDelayMs: +this.getConfig('startButtonDisplayDelayMs', '0'),
            authMode: this.getConfig('authMode', 'clientId'),
            opened: this.getConfig('opened', 'false'),
            jwtReference: this.getConfig('jwtReference', ''),
            isCampaign: this.getConfig('isCampaign', 'false') === 'true',
            botDisplayedName: this.getConfig('botDisplayedName', 'randy'),
        };
    }

    public get chatParams(): IChatParameters {
        return this.chatParameters;
    }

    public getReferral(): IReferral {
        this.getUrlAndDivValues();
        return {
            ref: this.computeRandyRef(),
        };
    }

    private getUrlAndDivValues(): void {
        this.divAttributes = document.getElementById('randy-webchat')?.attributes;
        this.urlParams = new URLSearchParams(window.location.search);
    }

    private computeRandyRef(): string {
        const refs: Array<string | null | undefined> = [];
        this.referralKeys.forEach((key) => {
            refs.push(this.urlParams?.get(`randy_${key}`));
            refs.push(this.divAttributes?.getNamedItem(key)?.value);
        });
        return refs.filter((v) => v).join('.');
    }

    private getConfig(name: string, defaultValue?: string): string {
        // Priority order
        // 1 : url param
        // 2 : div attribute
        // 3 : default for non mandatory settings
        const urlValue: string | null | undefined = this.urlParams?.get(name);
        if (urlValue) {
            return urlValue;
        }
        const divValue = this.divAttributes?.getNamedItem(name)?.value;
        if (divValue) {
            return divValue;
        }
        if (defaultValue !== undefined) {
            return defaultValue;
        }
        throw new Error(`Incomplete configuration : ${name} is undefined`);
    }
}

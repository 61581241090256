import { Observable, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { injectable } from 'vue-typescript-inject';

export type RequestInterceptor = (options: Partial<AjaxRequest>) => Partial<AjaxRequest>;
export type ResponseInterceptor = (options: Partial<AjaxResponse>) => any;

export interface HttpClientError {
    status: number | undefined;
    message: string;
}

@injectable()
export default class HttpClient {
    private authInterceptor?: RequestInterceptor;

    public request(
        options: AjaxRequest,
        requestInterceptor?: RequestInterceptor,
        responseInterceptor?: ResponseInterceptor,
    ): Observable<any> {
        return ajax(this.interceptRequest(options, requestInterceptor)).pipe(
            map((res) => this.interceptResponse(res, responseInterceptor)),
            catchError((err: AjaxError) => {
                return throwError(this.interceptError(err));
            }),
        );
    }

    public authenticatedRequest(options: AjaxRequest): Observable<any> {
        if (this.authInterceptor) {
            return this.request(this.authInterceptor(options));
        }
        return throwError('Missing Authentication');
    }

    public setAuthInterceptor(authInterceptor: RequestInterceptor): void {
        this.authInterceptor = authInterceptor;
    }

    public authIsUndefined(): boolean {
        return this.authInterceptor === undefined;
    }

    private interceptRequest(
        request: Partial<AjaxRequest>,
        requestInterceptor?: RequestInterceptor,
    ): Partial<AjaxRequest> {
        return (requestInterceptor ?? this.defaultRequestInterceptor)(request);
    }

    private interceptResponse(response: Partial<AjaxResponse>, responseInterceptor?: ResponseInterceptor): any {
        return (responseInterceptor ?? this.defaultResponseInterceptor)(response);
    }

    private interceptError(err: Partial<AjaxError>): HttpClientError {
        return {
            status: err.status,
            message: err.xhr?.response?.message,
        };
    }

    private defaultRequestInterceptor(req: Partial<AjaxRequest>): Partial<AjaxRequest> {
        if (req.body) {
            req.body = JSON.stringify(req.body);
        }
        return req;
    }
    private defaultResponseInterceptor(res: Partial<AjaxResponse>): any {
        return res.response;
    }
}
